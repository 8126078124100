var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100 bg-primary"},[_c('BContainer',{staticClass:"h-100 justify-content-center align-items-center d-flex"},[_c('BForm',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('img',{staticClass:"levno-banner",attrs:{"src":require("@/assets/levno_logo.svg")}}),_c('h5',{staticClass:"text-white pt-2"},[_vm._v("Partner")]),(!_vm.submitted)?_c('FormInput',{attrs:{"inputAttrs":{
          id: 'newPassword',
          label: 'New Password',
          labelClass: 'text-secondary',
          type: 'password',
          autocomplete: 'current-password',
          errorMessage: 'Please enter your new password'
        },"id":"newPassword"},model:{value:(_vm.$v.form.newPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.newPassword, "$model", $$v)},expression:"$v.form.newPassword.$model"}}):_vm._e(),(!_vm.submitted)?_c('FormInput',{attrs:{"inputAttrs":{
          id: 'confirmPassword',
          label: 'Confirm Password',
          labelClass: 'text-secondary',
          type: 'password',
          autocomplete: 'current-password',
          state: _vm.attemptSubmit,

          errorMessage: _vm.errorMessage
        },"id":"confirmPassword"},model:{value:(_vm.$v.form.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.confirmPassword, "$model", $$v)},expression:"$v.form.confirmPassword.$model"}}):_vm._e(),(!_vm.submitted)?_c('BButton',{staticClass:"text-light w-100 mb-3",attrs:{"type":"submit"}},[_vm._v("Reset Password")]):_vm._e(),(_vm.submitted)?_c('a',{staticClass:"text-secondary",attrs:{"href":"/auth/login"}},[_vm._v("Back to login page")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }